import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { computed, DestroyRef, Directive, effect, ElementRef, inject, input } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Subscription } from "rxjs";
import { Picture, PictureSize } from "@cg/content-api/typescript-interfaces";
import { PictureMediaQueries } from "@cg/core/ui";
import { EnvironmentService } from "@cg/environments";

@Directive({
  selector: "[cgxResponsiveBg]",
  standalone: true
})
export class ResponsiveBgDirective {
  private readonly el = inject(ElementRef);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly destoryRef = inject(DestroyRef);
  private readonly environmentService = inject(EnvironmentService);

  public src = input.required<Omit<Picture, "ngTemplate" | "whitespace">>();

  private _queries = computed(
    () => this.src()?.sizes?.map((picSize: PictureSize) => PictureMediaQueries[picSize.media]) ?? []
  );
  private _subscription: Subscription;

  public constructor() {
    effect(
      () => {
        this._setBgImgUrl(this.src().source);
        this._setAriaAttributes(this.src().alt);

        if (this._subscription) {
          this._subscription.unsubscribe();
        }

        if (this._queries().length > 0) {
          this._subscription = this.breakpointObserver
            .observe(this._queries())
            .pipe(takeUntilDestroyed(this.destoryRef))
            .subscribe((result: BreakpointState) => {
              if (!result.matches) {
                this._setBgImgUrl(this.src().source);
                return;
              }

              const breakpoint = Object.keys(result.breakpoints).find((key: string) => result.breakpoints[key]);
              const sizeKey = Object.keys(PictureMediaQueries).find(
                (key: string) => PictureMediaQueries[key] === breakpoint
              );
              const bgImgUrl = this.src().sizes.find((picSize: PictureSize) => picSize.media === sizeKey).srcset;
              this._setBgImgUrl(bgImgUrl);
            });
        }
      },
      { allowSignalWrites: true }
    );
  }

  private _setBgImgUrl(bgImgUrl: string) {
    if (bgImgUrl.startsWith("/dam/") || bgImgUrl.startsWith("/.imaging/")) {
      // Magnolia images sometimes dont have the full path, so we need to prepend the host in order to make them work
      bgImgUrl = `https://${this.environmentService.env.webContentApi}${bgImgUrl}`;
    }

    this.el.nativeElement.style.backgroundImage = `url(${bgImgUrl})`;
  }

  private _setAriaAttributes(alt: string) {
    this.el.nativeElement.setAttribute("role", "img");
    this.el.nativeElement.setAttribute("aria-label", alt);
  }
}
