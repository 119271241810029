import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import type { CgxCta } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "cgx-cta",
  templateUrl: "./cta.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, IconComponent, NgClass]
})
export class CtaComponent {
  private readonly domSanitizer = inject(DomSanitizer);
  public content = input.required<CgxCta>();
  public disabled = input<boolean>(false);

  public computedClasses = computed(() => {
    let classes = "";

    // as casting exists for KontoroFeatureFlagKeys.CGX_STAGE_RED_BUTTON_TEST
    switch (this.content().variation as "primary" | "secondary" | "primary_small" | "primary_legacy") {
      case "primary":
        classes =
          "px-8 bg-cg-green text-white hover:bg-cg-green-dark-15 cgxT:w-[var(--cta-touch-width,fit-content)] cgxD:w-[var(--cta-desktop-width,fit-content)] w-[var(--cta-width,100%)]";
        break;
      case "secondary":
        classes =
          "border border-cg-green bg-white px-8 text-cg-green hover:border-cg-green-dark-15 hover:text-cg-green-dark-15 w-[var(--cta-width,fit-content)]";
        break;
      case "primary_small":
        classes =
          "bg-cg-green !py-1.5 px-4 text-[13px] text-white hover:bg-cg-green-dark-15 cgxD:px-20 cgxD:!py-3 cgxD:text-base w-fit";
        break;
      // exists for KontoroFeatureFlagKeys.CGX_STAGE_RED_BUTTON_TEST
      case "primary_legacy":
        classes =
          "px-8 bg-cg-red text-white hover:bg-cg-red-dark-15 cgxT:w-[var(--cta-touch-width,fit-content)] cgxD:w-[var(--cta-desktop-width,fit-content)] w-[var(--cta-width,100%)]";
        break;
    }

    if (this.disabled()) {
      classes += " opacity-60 !cursor-not-allowed";
    }

    if (this.content().iconDirection === "left") {
      classes += " flex-row-reverse";
    }

    return classes;
  });

  public get sanitizedContent(): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(this.content().title);
  }
}
