@if (isSvg()) {
  <mat-icon
    class="pointer-events-none flex items-center"
    [svgIcon]="content().name"
    [ngStyle]="{ color: content().color }"
    [attr.aria-label]="content().alt"
  ></mat-icon>
} @else {
  <img class="icon" [src]="content()?.src" alt="content().alt" />
}
